// these are statuses which admins can always move to, and are always
// visible in the deal status options in the Admin Edit > Team component
export const UNRESTRICTED_BEGINNING_STATUSES = [
  'received',
  'submitted',
  'under_review',
];

// under_review is in both UNRESTRICTED_BEGINNING_STATUSES and
// RESTRICTED_STATUSES because it is the starting status in order
// to get the deal moving forward in RESTRICTED_STATUSES
export const RESTRICTED_STATUSES = [
  'under_review',
  'ready_for_approval',
  'term_sheet',
  'due_diligence',
  'closing_scheduled',
  'closed',
  'open_for_investment',
  'fully_funded',
  'funded_off_platform',
  'repaid',
  'warehoused',
];

export const POST_ORIGINATION_STATUSES = [
  'closed',
  'open_for_investment',
  'fully_funded',
  'repaid',
  'funded_off_platform',
  'warehoused',
];

export const POST_ARCHIVED_STATUSES = ['archived', 'received'];

export const ACTIVE_APPLICATION_STATUSES = [
  'received',
  'submitted',
  'under_review',
  'ready_for_approval',
  'term_sheet',
  'due_diligence',
  'closing_scheduled',
  'on_hold',
];

// none of the UNRESTRICTED_ENDING_STATUSES are in RESTRICTED_STATUSES
// because in order from an UNRESTRICTED_ENDING_STATUSES to get into
// RESTRICTED_STATUSES, it has to be put to "under_review" first
export const UNRESTRICTED_ENDING_STATUSES = [
  'on_hold',
  'declined',
  'borrower_terminated',
  'lost',
];

export const DEAL_TEAM_ROLE_TYPES = [
  'account_executive',
  'loan_quality_specialist',
  'real_estate_analyst',
  'underwriting_manager',
  'underwriting_team_lead',
  'servicing_analyst',
  'closing_specialist',
  'approver',
  'account_manager',
  'sales_development_rep',
  'territory_manager',
  'regional_sales_director',
  'internal_counsel',
  'legal_assistant',
  'collateral_analyst',
  'account_director',
  'appraisal_coordinator',
  'loan_process_coordinator',
  'asset_manager',
];

export const ROLES_WITH_TASKS = [
  'account_executive',
  'real_estate_analyst',
  'servicing_analyst',
  'closing_specialist',
  'approver',
  'internal_counsel',
  'legal_assistant',
  'collateral_analyst',
  'appraisal_coordinator',
];

export const ROLE_OPTIONS = [
  ['loan_quality_specialist', 'LQS'],
  ['account_executive', 'AE'],
  ['real_estate_analyst', 'REA'],
  ['servicing_analyst', 'SA'],
  ['closing_specialist', 'CS'],
  ['approver', 'APRV'],
  ['account_manager', 'AM'],
  ['internal_counsel', 'IC'],
  ['legal_assistant', 'LA'],
  ['collateral_analayst', 'CA'],
  ['appraisal_coordinator', 'AC'],
  ['loan_process_coordinator', 'LPC'],
];

// these statuses correlate to deal statuses
export const TASK_WORKFLOW_STATUSES = [
  'term_sheet',
  'closing_scheduled',
  'closed',
  'closing_specialist_audit',
  'open_for_investment',
];

export const TASK_STATUSES = [
  'not_started',
  'requested',
  'in_review',
  'complete',
  'rejected',
  ['n/a', 'N/A'],
];

export const STATES = [
  'AK',
  'AL',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
];

export const STATE_HASH = {
  AK: 'Alaska',
  AL: 'Alabama',
  AR: 'Arkansas',
  AZ: 'Arizona',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DC: 'Washington, D.C.',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  IA: 'Iowa',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  MA: 'Massachusetts',
  MD: 'Maryland',
  ME: 'Maine',
  MI: 'Michigan',
  MN: 'Minnesota',
  MO: 'Missouri',
  MS: 'Mississippi',
  MT: 'Montana',
  NC: 'North Carolina',
  ND: 'North Dakota',
  NE: 'Nebraska',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NV: 'Nevada',
  NY: 'New York',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VA: 'Virginia',
  VT: 'Vermont',
  WA: 'Washington',
  WI: 'Wisconsin',
  WV: 'West Virginia',
  WY: 'Wyoming',
};

export const FLOOD_ZONE_TYPE = [
  'not_exposed',
  'moderate_hazard',
  'high_hazard',
];

export const UNDERLYING_ASSET = [
  'Single Family',
  'Two Family',
  'Three Family',
  'Four Family',
  'Multi-Family (5+ Units)',
  'Mixed Use',
  'Portfolio',
  'Vacant Land',
];

export const LIQUID_NET_WORTH = [
  'Less than $25,000',
  '$25,000 - $50,000',
  '$50,000 - $100,000',
  '$100,000+',
];

export const LOCATION_TYPE = ['Primary', 'Secondary', 'Tertiary'];

export const DEAL_TYPE = [
  'Rehab',
  'New Construction',
  'No Rehab/No Construction',
];

export const ACQUISITION_TYPE_PURCHASE = 'Purchase';
export const ACQUISITION_TYPE_OWN_WITH_LOAN = 'Own with existing loan';
export const ACQUISITION_TYPE_OWN_FREE_AND_CLEAR = 'Own free and clear';

export const ACQUISITION_TYPES = [
  ACQUISITION_TYPE_PURCHASE,
  ACQUISITION_TYPE_OWN_WITH_LOAN,
  ACQUISITION_TYPE_OWN_FREE_AND_CLEAR,
];

export const PERSONAL_GUARANTEE = [
  'Personal Guarantee',
  'Personal Guarantee x2',
  'Personal Guarantee x3',
  'Personal Guarantee x4',
  'Personal Guarantee x5',
  'Personal Guarantee x6',
  'Personal Guarantee x7',
  'Personal Guarantee x8',
];

export const CONSTRUCTION_DRAW_STATUSES = [
  'estimated',
  'requested',
  'approved',
  'disbursed',
  'denied',
];

export const USE_OF_FUNDS = [
  'Construction Draw',
  'Interest',
  'Payoff Credit',
  'Draw At Close',
];

export const ORDERED_PRE_CLOSING_TASKS_CATEGORIES = [
  'property_valuation_and_inspection',
  'borrower_docs_and_information',
  'llc_documentation',
  'deal_information',
  'title_and_pre_close_documents',
  'funding_documents',
  'post_funding_actions',
  'custom',
];

// ORDER OF TASKS WITHIN PRE CLOSING TASKS
export const PCT_PROPERTY_VALUATION_AND_INSPECTION_TASKS_ORDER = [
  'Appraisal Review',
  'Redbell/Internal Valuation',
  'Appraisal Order',
  'Pre-close Inspection',
  'Pre-close Pictures',
];

export const PCT_BORROWER_DOCS_AND_INFORMATION_TASKS_ORDER = [
  'Intro Email',
  'Background Check',
  'Credit Check',
  'Purchase Contract',
  'Scope of Work',
  'Plans / Renderings',
  'Prior Experience',
  'Deposit (Asset/Liquidity)',
  'Photo ID',
  'P/C Insurance',
  'Flood Coverage Required?',
  'P/C Insurance Receipt',
  'Blank Check',
  'Borrower Notes',
  'Borrower Mailing Address',
];

export const PCT_LLC_DOCUMENTATION_TASKS_ORDER = [
  'Articles of Organization',
  'Certificate of Good Standing',
  'EIN',
  'Signed Operating Agreement',
  'Signatories',
];

export const PCT_DEAL_INFORMATION_TASKS_ORDER = [
  'File Notes',
  'Clear to Close',
  'Paydown Schedule',
  'As-Is Value Confirmed',
  'Borrower Cash Needs Confirmed',
  'Borrower Connections',
  'Construction Completed at Close Confirmed',
  'Construction to Be Completed After Close Confirmed',
  'Credit Score Confirmed',
  'Number of Construction Draws Confirmed',
  'Projects 24 Month Experience',
  'Purchase Price Confirmed',
  'Flood Coverage Confirmed',
];

export const PCT_TITLE_AND_PRE_CLOSE_DOCUMENTS_TASKS_ORDER = [
  'Closing Protection Letter',
  'Title Commitment',
  'Start Draft Docs',
  'Draft Document Review',
  'Term Sheet Checked',
  'Pre-Closing HUD',
];

export const PCT_FUNDING_DOCUMENTS_TASKS_ORDER = [
  'Marked Up Title',
  'Signed Loan Docs',
  'Wiring Instructions',
  'Wire Confirmation',
];

export const PCT_POST_FUNDING_ACTIONS_TASKS_ORDER = [
  'BSR Intro Email',
  'Borrower Connections',
];
// END OF ORDER OF TASKS WITHIN PRE CLOSING TASKS

export const ftfColors = {
  blue: '#1B82BA',
  darkBlue: '#1977AA',
  lightBlue: '#59A4CC',
  lighterBlue: '#c0e9ff',
  green: '#5DAF6D',
  darkGreen: '#4C974C',
  lightGreen: '#7ABD87',
  lighterGreen: '#99cea5',
  lightestGreen: '#d7ebdb',
  red: '#CE4949',
  darkRed: '#A93C3C',
  lightRed: '#D66A6A',
  lighterRed: '#f5c0c0',
  warning: '#f0ad4e',
  warningNote: 'rgba(240, 173, 78, 0.7)',
  yellow: '#fbe88e',
  yellowNote: 'rgba(251, 232, 142, 0.5)',
  grey1: '#666',
  grey2: '#777',
  grey3: '#999',
  grey4: '#bbb',
  grey5: '#d3d3d3',
  grey6: '#fafafa',
};

export const EXIT_STRATEGY = [
  'Sell',
  'Refinance',
  'Rent and Refinance',
  'Rent and Sell',
];

export const DAYS_PER_MONTH = 30;
export const DAYS_PER_YEAR = 360;

export const CONFIDENCE_SCORES = ['Placeholder', 'Targeted', 'Confirmed'];

const COMPANY_NAME = window.location.host.includes('upright')
  ? 'Upright'
  : 'Fund That Flip';

// these consts are used to set default text attributes
// (short_summary, valuation_summary, team_description,
//  market_description, project_description, project_strategy, risk)
// based on the scenario the user inputs.
// NOTE: DON'T change the indentations! It will change how the strings get intepreted
export const DEFAULT_TEXT_ATTRIBUTES = {
  // this is where there was no appraisal ordered and we are using ftf_arv
  internalValuationOnly: {
    short_summary: `UNDERLYING_ASSET home with BEDROOM bedrooms, FULL_BATH full baths, and HALF_BATH half baths in CITY, STATE. ARV per ${COMPANY_NAME} internal valuation is FTF_ARV. In addition to having repaid [X] ${COMPANY_NAME} loans, the developer has a [XXX, if above 700]+ credit score, and a track record of XXX [any relevant experience] in this market.`,
    valuation_summary: `${COMPANY_NAME}’s Internal Real Estate Analysts use industry standard valuation software, independent real estate data, and internal proprietary modeling to estimate the expected After Repair Value of the property.

${COMPANY_NAME} develops an internal valuation on all projects using an automated valuation model (AVM), which estimates real estate property valuations using mathematical modeling, combined with a database of real estate information for comparable properties. The comparable properties in the analysis are within [X.X] miles of the subject, have a similar number of bedrooms, bathrooms, and square footage, and are of similar home style.

The internal valuation of FTF_ARV on the property is supported by the following data points:
• [X] comparable properties have recently sold between [$XXX,000] and [$XXX,000].
• Price per square foot of these comparable properties ranges from [$XXX] to [$XXX].
• Our internal valuation yielded a price per square foot of [$XX].
• Gross monthly rents are estimated at [$XXX] for similar properties in the area.

${COMPANY_NAME} used the ${COMPANY_NAME} internal valuation when making the final decision to fund this project.`,
  },

  // this scenario is where we have ftf_arv AND an appraisal was ordered,
  // but we are using ftf_arv
  usingInternalValuation: {
    short_summary: `UNDERLYING_ASSET home with BEDROOM bedrooms, FULL_BATH full baths, and HALF_BATH half baths in CITY, STATE. ARV per ${COMPANY_NAME} internal valuation is FTF_ARV. In addition to having repaid [X] ${COMPANY_NAME} loans, the developer has a [XXX, if above 700]+ credit score, and a track record of XXX [any relevant experience] in this market.`,
    valuation_summary: `${COMPANY_NAME}’s Internal Real Estate Analysts use industry standard valuation software, independent real estate data, and internal proprietary modeling to estimate the expected After Repair Value of the property.

${COMPANY_NAME} develops an internal valuation on all projects using an automated valuation model (AVM), which estimates real estate property valuations using mathematical modeling, combined with a database of real estate information for comparable properties. The comparable properties in the analysis are within [X.X] miles of the subject, have a similar number of bedrooms, bathrooms, and square footage, and are of similar home style.

The internal valuation of FTF_ARV on the property is supported by the following data points:
• [X] comparable properties have recently sold between [$XXX,000] and [$XXX,000].
• Price per square foot of these comparable properties ranges from [$XXX] to [$XXX].
• Our internal valuation yielded a price per square foot of [$XX].
• Gross monthly rents are estimated at [$XXX] for similar properties in the area.

${COMPANY_NAME} supplemented our internal valuation with an appraisal. The appraisal indicated an ARV of APPRAISED_ARV for the property. We believe a more accurate portrayal of the subject property to be our internal analysis and valuation.

In review of the appraisal, ${COMPANY_NAME} noted the following items:
• The appraisal includes comps not in close proximity to the subject property (outside of [X.X] miles).
• The appraisal failed to appropriately account for the [additional square footage, bedrooms, bathroom, to be added as part of the planned renovations].
• After reviewing the borrower’s previous completed projects, we concluded the comps used in our internal valuation will be a more representative portrayal of the subject property’s final As Repaired Condition.

${COMPANY_NAME} used the ${COMPANY_NAME} internal valuation when making the final decision to fund this project.`,
  },

  // Internal Valuation + BPO Present – Using Internal Valuation
  internalValuationAndBPO: {
    short_summary: `UNDERLYING_ASSET home with BEDROOM bedrooms, FULL_BATH full baths, and HALF_BATH half baths in CITY, STATE. ARV per ${COMPANY_NAME} internal valuation is FTF_ARV. In addition to having repaid [X] ${COMPANY_NAME} loans, the developer has a [XXX, if above 700]+ credit score, and a track record of XXX [any relevant experience] in this market.`,
    valuation_summary: `${COMPANY_NAME}’s Internal Real Estate Analysts use industry standard valuation software, independent real estate data, and internal proprietary modeling to estimate the expected After Repair Value of the property.

${COMPANY_NAME} develops an internal valuation on all projects using an automated valuation model (AVM), which estimates real estate property valuations using mathematical modeling, combined with a database of real estate information for comparable properties. The comparable properties in the analysis are within [X.X] miles of the subject, have a similar number of bedrooms, bathrooms, and square footage, and are of similar home style.

The internal valuation of FTF_ARV on the property is supported by the following data points:
• [X] comparable properties have recently sold between [$XXX,000] and [$XXX,000].
• Price per square foot of these comparable properties ranges from [$XXX] to [$XXX].
• Our internal valuation yielded a price per square foot of [$XX].
• Gross monthly rents are estimated at [$XXX] for similar properties in the area.

${COMPANY_NAME} supplemented our internal valuation with a broker price opinion (BPO). The BPO indicated an ARV of BPO_ARV for the property. We believe a more accurate portrayal of the subject property to be our internal analysis and valuation.

In review of the BPO, ${COMPANY_NAME} noted the following items:
• The BPO includes comps not in close proximity to the subject property (outside of [X.X] miles).
• The BPO failed to appropriately account for the [additional square footage, bedrooms, bathroom, to be added as part of the planned renovations].
• After reviewing the borrower’s previous completed projects, we concluded the comps used in our internal valuation will be a more representative portrayal of the subject property’s final As Repaired Condition.

${COMPANY_NAME} used the ${COMPANY_NAME} internal valuation when making the final decision to fund this project.`,
  },

  // Internal Valuation + Appraisal + BPO Present – Using Internal Valuation
  internalValuationAndAppraisalAndBPO: {
    short_summary: `UNDERLYING_ASSET home with BEDROOM bedrooms, FULL_BATH full baths, and HALF_BATH half baths in CITY, STATE. ARV per ${COMPANY_NAME} internal valuation is FTF_ARV. In addition to having repaid [X] ${COMPANY_NAME} loans, the developer has a [XXX, if above 700]+ credit score, and a track record of XXX [any relevant experience] in this market.`,
    valuation_summary: `${COMPANY_NAME}’s Internal Real Estate Analysts use industry standard valuation software, independent real estate data, and internal proprietary modeling to estimate the expected After Repair Value of the property.

${COMPANY_NAME} develops an internal valuation on all projects using an automated valuation model (AVM), which estimates real estate property valuations using mathematical modeling, combined with a database of real estate information for comparable properties. The comparable properties in the analysis are within [X.X] miles of the subject, are similar in bedrooms, bathrooms, and square footage, and similar in home style.

The internal valuation of FTF_ARV on the property is supported by the following data points:
• [X] comparable properties have recently sold between [$XXX,000] and [$XXX,000].
• Price per square foot of these comparable properties ranges from [$XXX] to [$XXX].
• Our internal valuation yielded a price per square foot of [$XX].
• Gross monthly rents are estimated at [$XXX] for similar properties in the area.

${COMPANY_NAME} supplemented the internal valuation with an appraisal and a broker price opinion (BPO). The appraisal indicated an ARV of APPRAISED_ARV for the property; the BPO indicated an ARV of BPO_ARV.

In review of the appraisal, ${COMPANY_NAME} noted the following items:
• The appraisal includes comps not in close proximity to the subject property (outside of [X.X] miles).
• The appraisal failed to appropriately account for the [additional square footage, bedrooms, bathroom, to be added as part of the planned renovations].
• After reviewing the borrower’s previous completed projects, we concluded the comps used in our internal valuation will be a more representative portrayal of the subject property’s final As Repaired Condition.

In review of the BPO, ${COMPANY_NAME} noted the following items:
• The BPO includes comps not in close proximity to the subject property (outside of [X.X] miles).
• The BPO failed to appropriately account for the [additional square footage, bedrooms, bathroom, to be added as part of the planned renovations].
• After reviewing the borrower’s previous completed projects, we concluded the comps used in our internal valuation will be a more representative portrayal of the subject property’s final As Repaired Condition.

${COMPANY_NAME} used the ${COMPANY_NAME} internal valuation when making the final decision to fund this project.`,
  },

  team_description: `• [Detail relevant real estate background (certifications and profession)]\r
• [Disclose key metrics on real estate experience (years, deals)]\r
• [Comment on area of focus & type of development]\r
• [Note credit score]\r
• [Note why type of information we verified (tie smart tag to borrower contribution)]\
\n
EXAMPLE\r
This is the developer's [A] project funded with ${COMPANY_NAME}[, having successfully repaid B \
loans]. The developer focuses on projects in the [C] metropolitan area[, particularly D]. They \
typically execute [E] projects every 2 years. The developer has a strong background in [F fix and \
flips, residential renovations, residential new construction, multifamily rehabs, wholesaling, \
realtor experience, commercial new construction, etc.].\
 \n
[${COMPANY_NAME} has verified the developer has liquid assets in excess of the required developer \
contribution amount of approximately BORROWER_TOTAL.]\
 \n
The developer's credit score is CREDIT_SCORE.`,

  market_description: `• CITY is a city in [X] County, STATE\r
&nbsp;&nbsp;&nbsp;&nbsp;• # miles to CITY, STATE\r
&nbsp;&nbsp;&nbsp;&nbsp;• # miles to CITY, STATE\r
&nbsp;&nbsp;&nbsp;&nbsp;• # miles to CITY, STATE\r
• Retail Sales Sold: [X]%\r
• Short Sales Sold: [X]%\r
• REO Sold: [X]%\r

* data acquired from internal sources.`,

  project_description: `${COMPANY_NAME} closed the loan on this property on DATE_FUNDING_NEEDED. \
Your investment will begin accruing interest on the day it clears escrow.\
\n
${COMPANY_NAME} has provided a first distribution of TOTAL_NET_PROCEEDS_AMOUNT to the developer at \
closing for a loan to purchase of LOAN_TO_PURCHASE_FIRST_DISTRIBUTION. ${COMPANY_NAME} is holding \
back PREPAID_INTEREST_PLUS_STUB_INTEREST for PREPAID_INTEREST_PERIOD months of pre-paid interest.\
\n
The construction budget is EXPECTED_REHAB_BUDGET. ${COMPANY_NAME} will finance \
CONSTRUCTION_HOLDBACK of the total construction budget, [which includes a small contingency \
amount above the estimated rehab budget.] ${COMPANY_NAME} disburses construction funds based on \
verification of work performed, as determined by an independent 3rd party inspection firm. \
${COMPANY_NAME} reviews each inspection report and releases funds based on the percentage of \
completion of the project. ${COMPANY_NAME} expects to make NUMBER_OF_CONSTRUCTION_DRAWS \
construction draws, but may schedule additional inspections as needed. The final draw will be \
released after all work is verified to be complete.\
\n
[To be used if we’re not funding rehab.]\r
The construction budget is EXPECTED_REHAB_BUDGET. ${COMPANY_NAME} will not be financing the \
construction rehab for this loan but we have verified the borrower’s reserves to be sufficient to \
complete the project.\
\n
Over the course of the project, the developer will contribute an estimated BORROWER_TOTAL in \
equity, including an estimated BORROWER_AMOUNT_AT_CLOSE at the time of closing. The total \
loan-to-ARV is LOAN_TO_ARV_TOTAL_LOAN. There is a PREPAYMENT_PERIOD month pre-payment penalty, \
meaning investors will earn interest through PREPAYMENT_END_DATE, even if paid back earlier. The \
term of the underlying loan is MONTH_TERM months with an option to extend. Should ${COMPANY_NAME} \
choose to grant the extension(s), half of the extension fee(s) collected will be distributed to \
investors upon receipt.`,

  project_strategy: `The developer bought this property with financing from ${COMPANY_NAME} on DATE_FUNDING_NEEDED. \
Your investment will begin accruing interest the day it clears escrow.\
\n
Construction will begin shortly after loan closing and includes the following:\
\r
• \r
• \r
• \r
• \r
• \r
• \r
• \r
• \r
• \r
• \
\n
The full statement of work is available below.`,

  risk: `Having a thorough understanding of the risks of each investment in your portfolio \
is important prior to making an investment. ${COMPANY_NAME} encourages you to do full \
due diligence on each deal and consult your investment, tax and legal advisors prior to investing.\
\n
__Risk__:\r
The market value of the property drops significantly removing opportunity \
for the developer to make a profit.\
\n
__Mitigating Factor__:\r
The property is located in a stable market and was purchased at a discount \
providing downside protection in a falling-price environment.\
\n
__Risk__:\r
The developer chooses not to complete the project.\
\n
__Mitigating Factors__:\r
• The developer has considerable equity in the project and the LTV on an \
ARV basis is LOAN_TO_ARV_TOTAL_LOAN.\r
• ${COMPANY_NAME} holds a first position lien on the property and \
the partner has personally guaranteed the loan.\
\n
__Risk__:\r
The developer is unable to complete the project in the allotted MONTH_TERM month period.\
\n
__Mitigating Factor__:\r
${COMPANY_NAME} has built in a EXTENSION_TERM month optional extension,\
 approved only if project is advancing at a satisfactory pace. Extension \
 corresponds with an additional fee to be shared on a pro-rata basis with investors.\
\n
A more complete list of risks for this investment is provided in the Investor Offering \
Materials and should be read and reviewed with your investment, tax and legal advisors.`,
};
